<template>
  <div class="box box--infoBlock">
    <div class="box__inner">
      <div class="infoBlock infoBlock--document infoBlock--disclaimer">
        <div class="documentInfo">
          <div class="documentInfo__title">Privacy Policy</div>
          <div class="documentInfo__content js-customScroll">
            <vue-scroll :ops="ops">
              <article>
                <p>This is the Privacy Policy of FLY I Corporation, which does business as FLYJETS, whose principal
                  place
                  of business is located at 225 Liberty Street Suite 1020A New York, NY 10281 (the "Company"). This
                  Privacy Policy applies to personal information
                  and data that Company collects about you ("Personal Information") and information about your computer
                  or
                  mobile device ("Non-Personally Identifiable Information"). Such information may be collected by
                  Company
                  in connection with your use of
                  Company's website, services, applications or platforms.</p>
                <p>Personal Information that may be collected may include, but is not limited to: name, address, flight
                  information, contact information, billing information, credit/debit card number, trip information and
                  any other information you may be requested
                  to provide by Company or voluntarily provide to Company. Company may use the Personal Information
                  collected:</p>
                <ul>
                  <li>to submit a flight request to independent third party private aircraft operators,</li>
                  <li>to obtain quotes for flights for you,</li>
                  <li>to book a private flight for you,</li>
                  <li>to contact you,</li>
                  <li>in marketing communications,</li>
                  <li>to improve your website experience,</li>
                  <li>to understand our customers through sales and marketing research,</li>
                  <li>for Website usage (including but not limited to statistical analysis, sales and marketing
                    research,
                    tracking page views, activity and visitor navigation paths, marketing campaigns, and links from our
                    partners’ websites to our website),
                  </li>
                  <li>to improve its services and platforms,</li>
                  <li>to contact you with updates to and information about its products and services,</li>
                  <li>to identify and prevent fraud,</li>
                  <li>to perform other activities as described in this Privacy Policy.</li>
                </ul>
                <p>Company's policy is not to give, sell or otherwise distribute the Personal Information collected to
                  third parties outside of Company. Company will disclose your Personal Information for the purposes set
                  forth in this Privacy Policy or if required
                  by law. Company may also share your information in response to subpoenas, court orders or to defend
                  against legal claims or threatened claims. Company may use third party service providers to assist in
                  collecting, using or otherwise processing
                  Personal Information for the purposes described in this Privacy Policy. These service providers are
                  not
                  allowed to use the information that Company shares for any other purpose other than providing services
                  to Company. Company's practice is
                  to require its service providers to conduct activities on behalf of Company in a manner consistent
                  with
                  this Privacy Policy.</p>
                <p>Company is not responsible or liable for the independent activities or privacy policies at
                  third-party
                  sites. You should consult the privacy policies and other terms and conditions at those sites to
                  determine how your information may be used.
                  Once you have left our website any data you provide to a third party is subject to the privacy policy
                  and practices of that third party. Company accepts no responsibility for the content or use of third
                  party websites.</p>
                <h2>Social Media</h2>
                <p>If you use social media accounts to access Company's platforms, you agree to allow Company access to
                  certain components of your social media pages. Company may use widgets on these sites to collect
                  information such as your IP address to determine
                  if the social media components of Company's platforms are functioning correctly. These widgets may be
                  hosted by Company or a third-party provider.</p>
                <h2>Cookies and Web Beacons</h2>
                <p>A cookie is a series of data characters that, when programmed into a website, is placed by the web
                  server into the browser's application folder on your computer. Once placed onto your machine, the
                  cookie
                  will allow Company's platforms to "recognize"
                  your computer or mobile device. A cookie may also be combined with a web beacon, which is an invisible
                  image with a line of code which is placed within an email message or on a web page. Web beacons inform
                  Company if an intended recipient
                  can receive email in an HTML format or only as a text message, whether the email message has been open
                  or opened multiple times, and if a display ad has been viewed or clicked through. This information is
                  used to serve customized messaging
                  to visitors to Company's platforms. Web beacons do not create, modify or delete files on a user's
                  machine.</p>
                <p>Non-personally Identifiable Information captured through these technologies may include the
                  geo-location of your computer or mobile device, information about the specific pages you viewed while
                  on
                  the site, the sequencing and duration of your
                  views on those pages, the type of computer or mobile hardware you used to access the site, and the IP
                  address of the hardware. The information collected is used to provide Company with the capability to
                  personalize information for users and
                  remember any preferences that may have been set.</p>
                <p>Cookies can be removed depending on what type of web browser and what browser version you are using,
                  you may be able to change the properties on your cookie file so that cookies are not used or saved.
                  Check with your browser provider for more
                  information on removing cookies. If you refuse to accept cookies, you may not be able to access many
                  of
                  the tools offered on or through Company's platforms.</p>
                <h2>Non-personally Identifiable Information</h2>
                <p>Company also reserve the right to use and disclose data in aggregate form (i.e., Non-personally
                  Identifiable Information) for statistical and other purposes in Company's sole discretion. Company may
                  also disclose data to third-party companies
                  that provide support services such as web analytics. The information is provided in an aggregate form
                  (i.e., Non-personally Identifiable Information) for statistical and other purposes in Company's sole
                  discretion. In addition, your Personal
                  Information may be combined with non-personal information to customize your Company experience on
                  Company's platforms.</p>
                <p>Each email sent contains an easy, automated way for you to stop receiving email, or to change your
                  expressed interests. If you wish to do this, simply follow the instructions at the end of any email.
                  If
                  you have received unwanted, unsolicited
                  email sent via Company or purporting to be sent via Company, please forward a copy of that email with
                  your comment to FLY@flyjets.com for review.</p>
              </article>
            </vue-scroll>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PrivacyPolicy',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    metaInfo: {
      meta: [{
         name: 'robots', content: 'noindex',
      }],
    },
  }
</script>
